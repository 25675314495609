import { isMode } from "./core/utilities";

window.addEventListener("load", () => {
  // GET BODY
  const $body = document.querySelector("body");

  // Check if we are in local host testing
  if (isMode("development")) {
    // get local recipe
    import(/* webpackChunkName: "local" */ "./recipes/local");
  } else {
    // Check first for location pathname, if one it should be homepage
    if (
      window.location.pathname.split("/").filter((i) => i !== "").length === 1
    ) {
      import(/* webpackChunkName: "homepage" */ "./recipes/homepage");
      // Check for body tags in order to load recipes
    } else if ($body.classList.contains("Section-CategoryList")) {
      if ($body.classList.toString().includes("Section-ccc-custompage")) {
        // Custom pages
        import(/* webpackChunkName: "custom_pages" */ "./recipes/custom_pages");
      } else {
        // Parent Category page
        import(/* webpackChunkName: "categories" */ "./recipes/categories");
      }
    } else if ($body.classList.contains("Section-DiscussionList")) {
      if ($body.classList.contains("tagged")) {
        // list of discussion tagged
        import(
          /* webpackChunkName: "discussions_tagged" */ "./recipes/discussions_tagged"
        );
      } else if ($body.classList.contains("bookmarked")) {
        // My Bookmarks
        import(
          /* webpackChunkName: "discussions_bookmarked" */ "./recipes/discussions_bookmarked"
        );
      } else if ($body.classList.contains("mine")) {
        // My discussions
        import(
          /* webpackChunkName: "discussions_mine" */ "./recipes/discussions_mine"
        );
      } else {
        // Discussions
        import(/* webpackChunkName: "discussions" */ "./recipes/discussions");
      }
    } else if ($body.classList.contains("Section-PostDiscussion")) {
      if ($body.classList.contains("editdiscussion")) {
        // edit discussion
        import(
          /* webpackChunkName: "discussion_edit" */ "./recipes/discussion_edit"
        );
      } else {
        // Create discussion
        import(
          /* webpackChunkName: "discussion_create" */ "./recipes/discussion_create"
        );
      }
    } else if ($body.classList.contains("Section-Event")) {
      if ($body.classList.contains("edit")) {
        // edit event
        import(/* webpackChunkName: "event_edit" */ "./recipes/event_edit");
      } else if ($body.classList.contains("add")) {
        // Create event
        import(/* webpackChunkName: "event_create" */ "./recipes/event_create");
      }
    } else if ($body.classList.contains("Section-Discussion")) {
      // Discussion summary
      import(/* webpackChunkName: "discussion" */ "./recipes/discussion");
    } else if ($body.classList.contains("NewEvents")) {
      // Event summary
      import(/* webpackChunkName: "event" */ "./recipes/event");
    }
  }
});
